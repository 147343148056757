<script>
  export default {
    name: 'Modal',
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
<template>
    <div class="modal-backdrop">
      <div class="modal">
        <header class="modal-header">
          <slot name="header"></slot>
        </header>
  
        <section class="modal-body">
          <slot name="body">
            This app is intended to help you plan cross country excursions.
          </slot>
         </section>
  
        <footer class="modal-footer">
          <slot name="footer">
          </slot>
          <button
            type="button"
            class="btn-green"
            @click="close"
          >
            Close
          </button>
        </footer>
      </div>
    </div>
  </template>
  <style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:100;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 600px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: black;
    text-align: center;
    font-weight: 600;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 0px 20px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #123be2;
    border: 1px solid #667fe2;
    border-radius: 4px;
    width:80px;
    margin:auto;
  }
  .legend{
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}
@media only screen and (max-width: 1000px) {
    .modal {
        max-height: 100vh;
        overflow-y: scroll;
    }
}
</style>