<template>
  <div class="main-page">
    <h3 @click="afill">Enter stations separated by spaces</h3>&nbsp;
    <span class=example>Example:&nbsp;KPYM&nbsp;KBDL&nbsp;K1B1&nbsp;KAVP&nbsp;KLHV </span>
    <a class="info" href="" @click="showModal">Help</a><br />
    <input id="stations" v-on:keyup.enter="go" v-model="input_stations">
    <button @click="go">Go</button>
    <br /><br />
    <span v-if="message" class="message">{{ message }}</span>
    <div id="table-wrap" v-if="stations">
      <h2>{{ estDateTime }} ({{ utcDateTime }})</h2>
    
      <table class="wx-tbl">
        <tr>
          <th class="row-label"></th>
          <th class="col-label" v-for="(station) in stations" :key="station">
          {{ station }}
          </th>
        </tr>
        <tr v-for="(row) in rows" :key="row.key">
          <th class="row-label">{{row.label}}</th>
          <td v-for="(station) in stations" :key="station" :class="calcClass(row.key, wx_data[station][row.key][idx])">
          {{ render(row.key, wx_data[station][row.key][idx])}}
          </td>
        </tr>
      </table>
      <div class="slider-fitter">
        <vue-slider v-model="idx" :min="0" :max="22" :dotSize="26" :tooltip="'none'"></vue-slider>
      </div>
    </div>
    <div class="footer">
      Copyright <sup>&copy;</sup> 2023 AVXCWX
      <a class="info" href="" @click="showModal">Info</a>
      </div>

      <Modal
        v-show="isModalVisible"
        @close="closeModal"
      >
        <template v-slot:header>
          Aviation Cross Country Weather
        </template>

        <template v-slot:body>
          <p>This app is intended to help you plan cross country excursions.</p>
          <p>
            The data is taken directly from the NOAA National Blend of Models
            and is projected out for aproximately 3 days. For this reason we
            only provide data for stations included in the model. This should include
            most of the KXXX airports. If you are looking for an airport you don't normally 
            put a K in front of, put a K in front of it.
          </p>
          <p>
            Examples:
            <br />&nbsp;&nbsp;&ndash;&nbsp;Mansfield MA 1B9 is K1B9
            <br />&nbsp;&nbsp;&ndash;&nbsp;Leach CO 1V8 is K1V8
          </p>
          <p>
            You can find the complete list of stations 
            <a href="https://vlab.noaa.gov/web/mdl/nbm-stations-v4.0" target="_blank">on the NOAA site</a>.
            Data is refreshed hourly.
          </p>
          <p>
            Less than awesome ceiling or visibility are depicted by these colors
          </p>
          
          <span class="mvfr legend">MVFR</span>
          <span class="ifr legend">IFR</span>
          <span class="lifr legend">LIFR</span><br /><br />
          <p>And for Gusts</p>
          <span class="gusty legend">Gusting over 20</span>
          <p>
            This site claims absolutely no responsibility for any 
            decisions you or others in the airplane with you make.
          </p>
          

        </template>

        <template v-slot:footer>
        </template>
      </Modal>
  </div>
</template>

<script>
import axios from 'axios'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Modal from './About.vue';
import dtlib from '../lib/datelib'
import { useCookies } from "vue3-cookies";

export default {
  name: 'WX',
  setup() {
    //console.log("SETUP");
    const { cookies } = useCookies();
    return { cookies };
  },
  props: {
    msg: String,
    submsg: String,
    lcldate: String,
    utcdate: String,
  },
  computed: {
    estDateTime(){
      var dt = this.getDate();
      var hr = this.wx_data[this.stations[0]]['UTC'][this.idx];
      return dtlib.localDateTime(dt, hr);
    },
    utcDateTime(){
      var dt = this.getDate();
      var hr = this.wx_data[this.stations[0]]['UTC'][this.idx];
      return dtlib.utcDateTime(dt, hr);
    },
    queryParams(){
      console.log("query params:"+JSON.stringify(this.$route))
      return this.$route.query;
    }
  },
  components: {
    Modal,
    VueSlider
  },
  data () {
    return {
      input_stations:'',
      isModalVisible: false,
      wx_data: {},
      idx: 0,
      stations: null,
      rows: [
      {label:'Ceiling Feet', key:'CIG'},
      {label:'Visibility Miles', key:'VIS'},
      {label:'Temp F/C', key:'TMP'},
      {label:'Dew Point F/C', key:'DPT'},
      {label:'Sky Coverage', key:'SKY'},
      {label:'Lowest Cloud Base', key:'LCB'},
      {label:'Wind Direction', key:'WDR'},
      {label:'Wind Speed', key:'WSP'},
      {label:'Wind Gust', key:'GST'},
      ],
      message: ''
    }
  },
  mounted () {
    //console.log("MOUNTED " + JSON.stringify(this.$route.query));
    this.$gtag.pageview({ page_path: '/' })
    this.getInitialStations();
    //if(this.input_stations)this.go();
    document.title = "AVXCWX"; 
  },
  methods: {
    async go(){
      //this.$gtag.event('Search', { stations: this.input_stations });
      if(!this.checkInput())return;
      var ask = this.input_stations;//.toUpperCase().trim().split(" ");
      await this.load(ask);
      this.$router.push("/?stations="+ask.replaceAll(" ",","));
      this.cookies.set('last_search', this.input_stations);
    },
    async load(ask){
      ask = ask.toUpperCase().trim().split(" ");
      let res = await axios.get('/data/?stations=' + ask.join());
      var keys = Object.keys(res.data);
      var missing = this.checkStations(ask, keys);
      if(missing){
        this.message = "Station data not found for " + missing;
      }
      else this.message = '';
      this.wx_data = res.data;
      this.stations = keys.length > 0 ? this.setStations(ask,keys) : null;
    },
    setStations(ask, keys){
      var ret = [];
      ask.forEach(k => {
        if(keys.includes(k))ret.push(k);
      });
      return ret;
    },
    async getInitialStations(){
      var qry = this.$route.query.stations
      if(qry != undefined){
        qry = qry.replaceAll(","," ").trim();
        await this.load(qry);
        //console.log("FROM QRY:"+this.stations);
        this.input_stations = qry;
        this.cookies.set('last_search', qry);
      }else{
        var cookieVal = this.cookies.get('last_search');
        if(cookieVal != undefined && cookieVal != ''){
          //console.log("cookieval:"+cookieVal);
          this.$router.push("/?stations="+cookieVal.replaceAll(" ",","));
          await this.load(cookieVal);
          this.input_stations = cookieVal;
          //console.log("FROM COOKIER:"+this.stations);

        }

      }
    },
    checkInput(){
      this.message = null;
      if(!this.input_stations || this.input_stations.length == 0) {
        if(!this.input_stations) this.message = 'Enter stations like this "KPYM KBDL K1B1"';
        this.stations = null;
        this.wx_data = null;
        return false;
      }
      return true;
    },
    checkStations(ask, answer){
      var ret = '';
      var noK = true;
      ask.forEach(k => {
        if(!answer.includes(k) && k.trim() != ''){
          ret += ' ' + k;
          if(k.startsWith('K'))noK = false;
        }
      });
      if(ret != '' && noK){
        ret += '. Try adding a K to identifier.';
      }
      return ret;
    },
    getDate(){
      var darr = this.wx_data[this.stations[0]]['DT'].split("/");
      darr = darr.map(x => x.trim());
      var hrs = this.wx_data[this.stations[0]]['UTC'];
      var off = 1;
      if(hrs[0] == 21 && !darr[0]){
        darr[0] = dtlib.formatDate(dtlib.subtractDay(darr[1]));
        off = 0;
      }
      if(darr[darr.length-1] == ''){
        darr[darr.length-1] = dtlib.formatDate(dtlib.addDay(darr[darr.length-2]));
      }
      for(var i = 1; i < this.idx+1 && i < hrs.length; ++i)if(hrs[i] == 0)++off;
      var ret = darr[off];
      return ret;
    },
    render(key, val){
      switch(key){
        case "CIG":
        case "LCB":
          if(val == -88)return "unlim";
          if(val == -99)return "unlim";
          return val + "00";
        case "VIS":
          if(val < 0)return "unkown"
          if(val < 10)return "< 1";
          return val/10;
        case "IFV":
        case "SKY":
          return val + "%";
        case "WDR":
          return val + "0";
          case "TMP":
          case "DPT":
          return val + "/" + this.celsius(val);
      }
      return val;
    },
    calcClass(key, val){
      var ret = "row-data"
      if(val == -88)return ret;
      switch(key){
        case "CIG":
          if(val < 5)return ret + " lifr";
          if(val < 10)return ret + " ifr";
          if(val < 30)return ret + " mvfr";
          break;
        case "VIS":
          if(val < 10)return ret + " lifr";
          if(val < 30)return ret + " ifr";
          if(val < 50)return ret + " mvfr";
          break;
        case "GST":
          if(val >= 20)return ret + " gusty";
      }
      return ret;
    },
    celsius(f){
      return Math.round((f-32)*5/9);
    },
    afill(e){
      if (e.shiftKey) {
        this.input_stations = 'KPYM KBDL K1B1 KAVP KLHV';
      }
    },
    showModal(e) {
      this.isModalVisible = true;
      e.preventDefault();
    },
    closeModal() {
      this.isModalVisible = false;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wx-tbl {
  table-layout: auto;
  border:1px;
  width:100%;
  min-width: 390px;
}
.row-label{
  width: 150px;
  min-width: 150px;
  text-align: left;
}
.col-label{
  min-width: 100px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.main-page{
  text-align: left;
  width:60%;
  margin: auto;
}
.label{
  float: left;
  width: 20%;
}
.wx{
  display: inline-block;
  width: 60px;
}
#stations{
  width:60%;
}
.lifr{
  background-color:magenta;
  color:white;
}
.ifr{
  background-color:red;
  color:white;
}
.mvfr{
  background-color:blue;
  color:white;
}
.gusty{
  background-color:#dddddd;
}
td {
  text-align: center;
}
th {
  text-align: center;
}
.footer {
    padding-top:30px;
 }
a {
  text-decoration: none;
  color:#123be2;
  font-weight: 600;
}
a:hover {
  color:#667fe2;
}
.info{
  display: inline-block;
}
h3{
  display: inline;
}
.example{
  font-size: small;
}
.message{
  color:red;
}
@media only screen and (max-width: 1000px) {
  .main-page{
    width:100%;
  }
  #stations{
    width:85%;
  }
  #table-wrap {
    width:100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    position: relative;
  }
  h2{
    font-size: 1.2em;
  }
  h3{
    font-size: .9em;
  }
  .slider-fitter{
    width:80%;
    margin:auto;
  }
  .wx-tbl {
    table-layout: fixed;
    min-width: 390px;
  }
  .row-label{
    width: 140px;
  }
  .col-label{
    width: 50px;
  }
  .row-data{
    width: 50px;
  }
}
</style>
