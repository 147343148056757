<template>
  <div id="app">
    <div class="hdr-img">&nbsp;
      <h1>AVXCWX</h1>
    </div>
    <h2>Aviation Cross Country Weather</h2>
    <WX msg="Welcome to XC WX" submsg="Boston"/>
  </div>
</template>

<script>
import WX from './components/WX.vue'

export default {
  name: 'App',
  metaInfo() {
    return {
      title: 'XXXXX AVXCWX',
      // ...
    };
  },
  components: {
    WX
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
.hdr-img{
  margin:auto;
  background-image: url('./assets/210.jpg');
  width: 340px;
  height: 171px;
  box-shadow: 0 0 10px 10px white inset;
}
h1 {
  margin-top:90px;
  color:white;
}
</style>
