import { createApp } from 'vue'
import VueGtag from "vue-gtag";
import App from './App.vue'
import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "App",
    component: App,
    props: route => ({ query: route.query.q })
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);

app.use(
  VueGtag, {
    config: { id: "G-D4EEFY1NHH" }
  }
);

app.use(
  router
);

router.isReady().then(() => {
  app.mount('#app')
})

//app.mount('#app');
